:root {
    /* Breakpoint */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    /* Flex paddings  and margins*/
    --padding: 1rem;
    --vertical-padding: 1rem;
    --margin: .5rem;
    --vertical-margin: .5rem;
    /* Fixed header */
    --fixed-header-height: 10vh;
    /* Slick */
    --slick-slider-height: 45vh;
    /* Colors */
    --text-color: #28241B;
    --main-color: #28241B;
    --accent-color: #E20613;
    --effect-color: #E20613;
    --header-text-color: #fff;
    --header-bg-color: var(--main-color);
    --footer-bg-color: var(--main-color);
    --footer-text-color: #C8C7C4;
    --menu-bg-color: inherit;
    --menu-text-color: inherit;
    --menu-text-hover-color: #fff;
    --menu-text-bg-color: inherit;
    --menu-text-bg-hover-color: color(var(--main-color) lightness(25%));
    /* Typo */
    --scale-factor: 1.5;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/Lato.css";

/*
@import "/cssmodules/modules/photoswipe.css";
@import "/cssmodules/modules/animate.css";
*/

* {
    letter-spacing: 0 !important;
}

.flex img {
    width: 100%;
}

html {
    height: 100%;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-weight: 400;
    font-family: Lato, sans-serif;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    color: var(--text-color);
    background: color(var(--main-color) lightness(95%));
}

/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

header {
    background: var(--header-bg-color);
    color: var(--header-text-color);
    box-shadow: 0 3px 3px rgba(0,0,0,0.3);

    z-index: 100;
    height:120px;
}

footer {
    text-align: center;
    padding: 1rem 0;
    background: var(--footer-bg-color);
    color: var(--footer-text-color);
}

header a {
    text-decoration: none;
}

.logo {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor))rem;
  
}

.logo a {

    position: absolute;
  z-index:1001;
}

.logo span {
    display: block;
}

.logo span:last-of-type {
    display: block;
    font-size: calc(1 * 1)rem;
}

.startsida section:last-of-type {




}

/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h3 {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
/*--------------------------------------------------------------
Menu
--------------------------------------------------------------*/

nav {
    background: var(--menu-bg-color);
}

.menu.menu li {

    margin:0;

}

.menu.menu li a {
    padding: .5rem .8rem;
    color: var(--menu-text-color);
    background: var(--menu-text-bg-color);
}

.menu.menu li.active a, .menu.menu li a:hover {
    background: var(--menu-text-bg-hover-color);
    color: var(--menu-text-hover-color);
}

/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/

.slick {
border-bottom: 7px solid var(--main-color);
}

.slick-slider {
    margin: 0;
}

.slick-slide {

    background-size: cover;
   
}


/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    flex: 1 0 auto;
}

header, footer {
    flex: none;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
    outline: 0 !important;
}

.button {

margin:0;
color: #c8c7c4;
background: var(--main-color);
}

figure {

    margin:0;

}

/*--------------------------------------------------------------
Maps
--------------------------------------------------------------*/

.map-canvas {
    height: 50vh;
}

.map-canvas img {
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
    
    .menu li a, .menu li li a {
    margin:0;
    padding: 10px;
    text-align: right;
}
    
        .content {

    margin-top:120px;
}
    
      .startsida  .content {

    margin-top:0px;
}
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    

}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
}

.intenditfooter {
font-size: .5rem;
    text-transform: uppercase;
    color: rgba(255,255,255,0.5);
    margin: 0 .5rem 0 0;
    text-align: right;
}

.intenditfooter a {
    text-decoration: none;
}
